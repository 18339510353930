import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useAssetSettings } from '@nsd/fe';
import NavBar from './NavBar';
import { SplashScreen } from '@nsd/fe';
import TopBar from './TopBar';
import { getNavBarItems } from '@nsd/fe';
import { useApp } from '@nsd/fe';
import { useSelector } from 'react-redux';
import BottomBar from './BottomBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: 'calc(100% - 24px)',
    overflow: 'auto',
  },
}));

/**
 * One of the 3 selectable layouts (MainLayout, DashboardLayout and DocsLayout) used as the
 * 'layout' field in an appConfig object. It is passed in a navConfig object ,
 * a topBarConfig (both from appConfig), the array of local app configs the user has,
 * and it is automatically wrapped around the necessary nested routes from the app (children).
 *
 * App name and version are used in the TopBar to display the name and version of the app
 *
 */
const DashboardLayout = ({ navConfig, topBarConfig, apps = [], children }) => {
  const { type, appInternalName } = useSelector((state) => state.application);

  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [navBar, setNavBar] = useState({
    isLoading: true,
    navBarItems: null,
  });
  const { hasModuleAccess } = useAssetSettings();
  const currentApp = useApp();
  const theme = useTheme();

  useEffect(() => {
    const loadNavBarItems = async () => {
      setNavBar({
        isLoading: false,
        navBarItems:
          typeof navConfig?.navBarItems === 'function'
            ? await navConfig?.navBarItems()
            : navConfig?.navBarItems,
      });
    };

    loadNavBarItems();
  }, [navConfig]);

  if (navBar.isLoading) return <SplashScreen appName={appInternalName}/>;

  const navBarItemsUserHasAccessTo = getNavBarItems(
    currentApp.path.replace('/', ''),
    navBar.navBarItems,
  ).map((navBarItem) => {
    const itemsUserHasAccessTo = navBarItem.items.filter(
      ({ module }) => !module || hasModuleAccess(module),
    );
    return { ...navBarItem, items: itemsUserHasAccessTo };
  });

  const navConfigWithUserAllowedModules = {
    ...navConfig,
    navBarItems: navBarItemsUserHasAccessTo,
  };

  if (!type) return null;
  return (
    <div className={classes.root}>
      <TopBar
        id="top-bar"
        data-test="top-bar"
        apps={apps}
        onMobileNavOpen={() => {
          setMobileNavOpen((prev) => !prev);
        }}
        {...topBarConfig}
      />
      <NavBar
        id="nav-bar"
        data-test="nav-bar"
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        navConfig={navConfigWithUserAllowedModules}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
      {theme?.layout?.hideBottomBar
        ? null 
        : <BottomBar id="bottom-bar" data-test="bottom-bar" />
      }
    </div>
  );
};

DashboardLayout.propTypes = {
  /**
   * navConfig object that determines what links to populate
   * in the NavBar. Comes from the appConfig of the current
   * rendered route.
   *
   * Within one object of items array you can create an other
   * items array (same shape) within it to have collaspable subroutes render
   * in the navBar.
   *
   * excludedPaths: Is an array of relative links
   *  ex: ['/', 'admin/path1'] that you don't want the
   *  current nav item to be rendered on.
   *
   * module: A module identifier you wish to guard the
   * navItem behind, if the user doesn't have access the link
   * is not rendered in the navBar. Leave off for a page
   * anyone can access
   */
  navConfig: PropTypes.shape({
    navBarItems: PropTypes.arrayOf(
      PropTypes.shape({
        subheader: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string.isRequired,
            icon: PropTypes.object,
            href: PropTypes.string.isRequired,
            items: PropTypes.array,
            excludedPaths: PropTypes.arrayOf(PropTypes.string),
            module: PropTypes.string,
          }),
        ),
      }),
    ),
    navBarMessage: PropTypes.shape({
      header: PropTypes.string,
      link: PropTypes.string,
      body: PropTypes.string,
    }),
  }),
  /**
   * topBarConfig object that sets any additional props that
   * are able to be set on TopBar including what widgets you
   * want to be rendered.Comes from the appConfig of the current
   * rendered route (consider keeping this identical for all appConfig
   * objects so the layout/widgets don't change for different pages)
   */
  topBarConfig: PropTypes.shape({
    className: PropTypes.string,
    /**
     * Used to render a series of additional data sources/options
     * if you wish to render more misc data in the TopBar
     */
    dataContextOptions: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.any,
        value: PropTypes.any,
        name: PropTypes.string,
        secondLine: PropTypes.string,
      }),
    ),
    /**
     * Callback that runs when the dataContext menu has a new option
     * selected. It receives 1 argument the generated event object (from Autocomplete)
     */
    onDataContextChange: PropTypes.func,
    contacts: PropTypes.bool,
    search: PropTypes.bool,
    // Must have Firebase Provider set up in src/index.js
    notifications: PropTypes.bool,
    settings: PropTypes.bool,
  }),
  /**
   * Array of appConfig objects imported as an array from src/Apps
   * locally. Has everything needed to render pages/routes.
   */
  apps: PropTypes.arrayOf(
    PropTypes.shape({
      appId: PropTypes.string.isRequired,
      appName: PropTypes.string.isRequired,
      site: PropTypes.string,
      icon: PropTypes.string.isRequired,
      priority: PropTypes.number,
      path: PropTypes.string.isRequired,
      guard: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
      layout: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
      routes: PropTypes.arrayOf(
        PropTypes.shape({
          exact: PropTypes.bool,
          path: PropTypes.string.isRequired,
          redirect: PropTypes.string,
          view: PropTypes.object,
        }),
      ),
      navConfig: PropTypes.shape({
        navBarItems: PropTypes.arrayOf(
          PropTypes.shape({
            subheader: PropTypes.string.isRequired,
            items: PropTypes.arrayOf(
              PropTypes.shape({
                title: PropTypes.string.isRequired,
                icon: PropTypes.object,
                href: PropTypes.string.isRequired,
                items: PropTypes.array,
                excludedPaths: PropTypes.arrayOf(PropTypes.string),
                module: PropTypes.string,
              }),
            ),
          }),
        ),
        navBarMessage: PropTypes.shape({
          header: PropTypes.string,
          link: PropTypes.string,
          body: PropTypes.string,
        }),
      }),
      topBarConfig: PropTypes.shape({
        className: PropTypes.string,
        dataContextOptions: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.any,
            value: PropTypes.any,
            name: PropTypes.string,
            secondLine: PropTypes.string,
          }),
        ),
        onDataContextChange: PropTypes.func,
        contacts: PropTypes.bool,
        search: PropTypes.bool,
        notifications: PropTypes.bool,
        settings: PropTypes.bool,
      }),
    }),
  ),

  /**
   * Used by Routes.js component to render any child routes
   * wrapped in the DashboardLayout
   */
  children: PropTypes.any,
};

export default DashboardLayout;