export default {
  dispatch: 'Dispatch',
  dispatches: 'Dispatches',
  reports: 'Reports',
  calls: 'Calls',
  networkMap: 'Network Map',
  operationalReports: 'Operational Reports',
  towOrder: 'Tow Order',
  towOrderCox: 'Cox Auto Tow Order',
  towOrderTuro: 'Turo Tow Order',
  impoundOrder: 'Impound Order',
  sixtImpoundOrder: 'Sixt Impound Order',
  repoOrder: 'Repo Order',
  callSearch: 'Call Search',
  dispatchSearch: 'Dispatch Search',
  impoundCalls: 'Impound Calls',
  submittedOrders: 'Submitted Orders',
  roadsideCalls: 'Roadside Calls',
};
