import { getFromLocalStorage } from '@nsd/fe';
import DEV_LOCAL_STORAGE_KEY from 'src/constants/localStorage/dev';
import NAMESPACE from 'src/constants/localStorage/namespace';
import { SITE } from 'src/sites';
import { APP_ENV } from 'src/utils/env';

const NEW_COMPANY_CODE = {
  volkswagengroupofamerica: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e812bf8e1-db2d-435d-9a71-827711ea1e6b',
  volkswagen: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8efafaa122-3dff-4e93-852a-70317a8c149a',
  audi: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e54264aee-a5f1-4acd-a399-cc663d6e7c39',
  lamborghini: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e5b96a1ad-c32d-4b1b-9087-7a26867f5225',
  bentleymotors: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e5dc2781b-1e02-4688-b51d-9513948b1dee',
  horacemann: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8ef2d41f58-c29a-4df6-9dd4-975b9e708810',
  afrmic: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e25c769e5-4aee-4e28-93e8-0c2c0705c9ae',
  turo: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8ed921c277-0af9-4963-9ada-49425a6f28f1',
  sixt: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e860d6abd-f0ac-4ebf-bcc4-43b3a69e3487',
  kemper: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e8269f20c-b962-41ee-887d-5bf0efad485a',
  mercedes: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8ee37b49c2-ed1c-4a72-a067-6685dea1284c',
  bridgestone: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8eb1ed3b2f-86e9-4a24-8605-77cff1222b49',
  ford: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e6eb85eb4-31f2-404f-ac8f-995e05db06ea',
  camelback: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e2308f83d-5beb-4cfc-a1a4-7783e8e289f0',
  oxcarcare: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e01c29e22-758a-458b-91e0-cab7f6191043',
  thegeneralinsurance: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8ec3a92331-b398-46fc-ae1c-9d4c8d4cab95',
  nesna: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8ee919d706-fac1-4900-b775-1c24920c4574',
  birchwood: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e3a0aceba-7259-4947-894e-09d868ad0184',
  pwi: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e586000bb-90b4-430f-ad7d-e5ce56ad5042',
  amazon: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e00875a48-2112-49dd-8a8a-19aaa3c74765',
  simplecar: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8ed639145d-9d95-4ec1-aa41-eb9d8fbc0513',
  firstinnovations: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e71935a41-830e-4f07-8d53-84db6ec1ac52',
  utica: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e4fbf1d1d-5d64-41d9-8fc4-57c6fed45941',
  royalenfield: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8ec64bdcea-ad32-4bdc-9027-933729a242c3',
  dealergeneral: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8ea6e3247c-ea29-49bc-bebb-4c78f221c613',
  excelaprotection: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8ea45da264-edd2-4431-8eae-347195e5b504',
  empirestate: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e82ef44e4-7aac-4b57-b272-70c257e55099',
  nac: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e69f65165-44dc-40cd-9c33-dfc35c279a1b',
  mss: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e601e2bdc-92a7-412b-8d4d-213a4f5991f9',
  everythingbreaks: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e8e6adefe-64de-4c07-b0b6-31da2f34cbda',
  gallagheraffinity: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e655ac06a-ef40-424e-830a-f2d8c1239e9f',
  preferreddealer: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e678dd112-db23-48f2-a615-f97168e4f04f',
  portfolio: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e4c2f436a-b090-4097-9c09-c110bc471ff1',
  bg: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e38672bad-6886-47dc-986f-2c503bf94cf1',
  goodsam: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8eccaeb6fd-28c8-4e5c-b45c-f2b9a039f515',
  meemic: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8ea6ba55a4-8fea-41e4-aa94-635f16aef840',
  towpanda: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e632cab62-2d1c-4fb1-9b52-8d5d71afdad7',
  lhm: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8efea0103b-7d81-4b43-8601-7cb2634388b4',
  dkpadmin: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e6c2183e9-b6d7-4c7a-8d27-7f967de63b0b',
  expressservice: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e5967c50d-d28c-4cc8-b628-312c0a07c9f8',
  flexcar: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e8611662b-b1C0-415b-a48a-97545c77b11a',
  carparts: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e90BC0069-5DCF-4548-BA44-5FCE9FC559BD',
  inspirationmobility: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8eD34A2141-ACD7-4B46-87A2-596B2ACB2DAE',
  pioneerinsurance: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e5f1d1f7c-029d-4905-a086-d15a8303d65c',
  coveragex: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e4f58f574-0d39-4da6-8f74-1fd65dcd8013',
  stateauto: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e4F45AA52-1D57-466E-A34F-3D24A6341C3F',
  alfa: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e9278CEB0-6BB9-4355-B1CE-D54F710E8034',
  monro: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e52DC0DD6-6EA3-47CE-8ACB-2A81C58950C7',
  endurance: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e30ABC8F0-3825-4DCC-BEFA-5AE0D68961F7',
  fimc: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e34e69d25-e9f8-4fa4-b753-15ae9bd4c6ec',
  encore: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8edeb329c0-e280-42ca-a4da-bdf1c098fc55',
  hanover: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e63bb2552-feea-4f55-b176-a46ea8a08cc1',
  farmers: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e21cbf85f-1aca-401d-9806-d6ec35ff8857',
  selective: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e9073b3a4-4708-4368-a72d-9605d657bfaf',
  grange: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e92bc409c-38a4-47a4-9af1-128d62793f6a',
  kyte: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8ea6800c66-1b75-4e63-a3df-b34d1723983e',
  drivesmartwarranty: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8eeb4507fd-97f6-40ef-89bf-917e8276623d',
  tesla: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e136961ef-da79-4fb4-b84d-2835d219a792',
  foxrentacar: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8eab690879-4ee9-4ae2-8e96-a8d663babca8',
  pemco: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8ece89a3e7-e182-4996-a0b8-a5caf000a194',
  vafb: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e00cd4c44-af5e-4aeb-b6d0-c4116b9dca8d',
  meineke: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8efa744e84-8390-4482-b717-bd26a7824583',
  iaawg: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e1448bee8-2812-4255-9a19-8433b788d276',
  secura: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e51ad70f5-efe3-4d5f-9cbe-5e4465dcfdc0',
};

// TODO (dn): Opt to maybe use the client's user id to determine company code the NSD client is with through tblUserCompany?
/**
 * Retrieves the company code based on which client portal the user is currently on.
 * @returns Company code
 */
export const getClientCompanyCode = () => {
  const environment = process.env.REACT_APP_ENVIRONMENT;

  let url = document.location.host;

  /**
   * These are the following hostnames for demo site:
   *
   * [DEV]: https://client-qore-dev.nationsafedrivers.com
   * [PERF]: https://client-qore-perf.nationsafedrivers.com
   * [STAGE]: https://client-qore-stg.nationsafedrivers.com
   * [UAT]: https://client-qore-uat.nationsafedrivers.com
   * [DEMO]: https://client-qore-demo.nationsafedrivers.com
   * [PROD]: https://client-qore.nationsafedrivers.com
   *
   * Return company code as 'nsddemo' if current hostname is any of the above demo sites by checking if 'client-qore' is a substring of the host name.
   */
  if (url.includes('client-qore')) {
    return 'nsddemo';
  }

  let companyCode = url
    .replace(/(^\w+:|^)\/\//, '')
    .split('.')[0]
    .split('-')[0];

  switch (environment) {
    case APP_ENV.DEMO:
      companyCode = NEW_COMPANY_CODE[companyCode] ?? companyCode;
      break;
    case APP_ENV.DEVELOPMENT:
      companyCode = NEW_COMPANY_CODE[companyCode] ?? companyCode;
      break;
    case APP_ENV.UAT:
      companyCode = NEW_COMPANY_CODE[companyCode] ?? companyCode;
      break;
    case APP_ENV.PERFORMANCE:
      companyCode = NEW_COMPANY_CODE[companyCode] ?? companyCode;
      break;
    case APP_ENV.STAGING:
      companyCode = NEW_COMPANY_CODE[companyCode] ?? companyCode;
      break;
    case APP_ENV.PRODUCTION:
      companyCode = NEW_COMPANY_CODE[companyCode] ?? companyCode;
      break;
    default:
      url =
        getFromLocalStorage(`${NAMESPACE.DEV}.${DEV_LOCAL_STORAGE_KEY.SITE}`) ??
        SITE.NSD_DEMO;

      // If mock portal is client-qore.nationsafedrivers.com, map it to nsddemo company code. This seems redundant since we do this in line 40 but is different since we retrieve it from local storage instead.
      if (url.includes('client-qore')) {
        url = SITE.NSD_DEMO;
      }

      companyCode = url
        .replace(/(^\w+:|^)\/\//, '')
        .split('.')[0]
        .split('-')[0];
      companyCode = NEW_COMPANY_CODE[companyCode] ?? companyCode;

      break;
  }
  return companyCode;
};

export const getPortalByCompanyCode = (companyCode) => {
  return Object.keys(NEW_COMPANY_CODE).find(key => NEW_COMPANY_CODE[key] === companyCode) || 'nsddemo';
};

export const isLocalEnvironment = document.location.host.includes('localhost');